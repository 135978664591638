const LARGE_URL = "https://imagedelivery.net/5fR4nwZCnV1pDzEiMNzCWg/";

export const GetExtraLargeUrl = (id?: string) =>
  `${LARGE_URL}${id}/width=1200,height=1200,fit=cover`;

export const GetLargeUrl = (id?: string) =>
  `${LARGE_URL}${id}/width=550,height=550,fit=cover`;
//64997da730f61a000260e3f4/public
const SMALL_URL = "https://imagedelivery.net/5fR4nwZCnV1pDzEiMNzCWg/";

export const GetSmallUrl = (id: string) =>
  `${SMALL_URL}${id}/width=275,height=275,fit=cover`;

const VARIATION_URL = "https://imagedelivery.net/5fR4nwZCnV1pDzEiMNzCWg/";
export const GetVariationUrl = (id: string) =>
  `${VARIATION_URL}${id}/width=80,bg=white`;
